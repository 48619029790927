import axios from 'axios';
// import moment from 'moment'
// import md5 from 'md5'
import { getSign } from '@/utils/utils'

// 请求配置
const service = axios.create({
  method: 'post',
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 0 // 超时时间 0不限制
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    const sign = getSign()
    // const timestamp = moment().unix()
    // const salt = '81ad0be7fd53914f8cf8193c1886f635'
    // const sign = md5(timestamp + salt)
    config.data = { ...config.data, timestamp: sign.timestamp, sign: sign.sign }
    return config
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default service;